import { FC } from "react";

export const AthletesIcon: FC = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.77075 33.3334C5.90825 33.3334 5.20825 34.0334 5.20825 34.8959C5.20825 35.7584 5.90825 36.4584 6.77075 36.4584C7.63325 36.4584 8.33325 35.7584 8.33325 34.8959C8.33325 34.0334 7.63325 33.3334 6.77075 33.3334Z"
        fill="#199BE2"
      />
      <path
        d="M26.0395 29.1667L20.4958 25.375L15.5395 31.7333L6.77075 33.3333V36.4583L15.9103 36.649C16.3978 36.6594 16.8739 36.4979 17.2551 36.1927L26.0395 29.1667Z"
        fill="#199BE2"
      />
      <path
        d="M41.1458 26.0416C42.0087 26.0416 42.7083 25.3421 42.7083 24.4791C42.7083 23.6162 42.0087 22.9166 41.1458 22.9166C40.2828 22.9166 39.5833 23.6162 39.5833 24.4791C39.5833 25.3421 40.2828 26.0416 41.1458 26.0416Z"
        fill="#199BE2"
      />
      <path
        d="M41.1459 26.0417H34.3584C33.7907 26.0417 33.248 25.8104 32.8553 25.4011L27.499 19.8219L30.7865 15.5427L35.3574 22.1979L41.1459 22.9167V26.0417Z"
        fill="#199BE2"
      />
      <path
        d="M30.2083 8.33329C30.2083 10.6333 32.0728 12.5 34.3728 12.5C36.678 12.5 38.5416 10.6333 38.5416 8.33329C38.5416 6.03329 36.678 4.16663 34.3728 4.16663C32.0728 4.16663 30.2083 6.03329 30.2083 8.33329Z"
        fill="url(#paint0_linear_9639_65047)"
      />
      <path
        d="M23.9583 16.6667C23.9583 18.9667 25.8228 20.8333 28.1228 20.8333C30.428 20.8333 32.2916 18.9667 32.2916 16.6667C32.2916 14.3667 30.428 12.5 28.1228 12.5C25.8228 12.5 23.9583 14.3667 23.9583 16.6667Z"
        fill="#35C1F1"
      />
      <path
        d="M18.75 26.0417C18.75 28.3417 20.6146 30.2083 22.9146 30.2083C25.2198 30.2083 27.0833 28.3417 27.0833 26.0417C27.0833 23.7417 25.2198 21.875 22.9146 21.875C20.6146 21.875 18.75 23.7417 18.75 26.0417Z"
        fill="#35C1F1"
      />
      <path
        d="M29.6875 42.7084C28.825 42.7084 28.125 43.4084 28.125 44.2709C28.125 45.1334 28.825 45.8334 29.6875 45.8334C30.55 45.8334 31.25 45.1334 31.25 44.2709C31.25 43.4084 30.55 42.7084 29.6875 42.7084Z"
        fill="#35C1F1"
      />
      <path
        d="M31.8657 18.5052L24.7625 14.4313L19.175 24.2094L19.8303 28.8375L25.9365 34.9177L28.1782 44.674L31.2459 44.1656L30.5803 33.5365C30.5636 33.2698 30.4959 33.0094 30.3813 32.7688L27.4021 26.5313L31.8657 18.5052Z"
        fill="#35C1F1"
      />
      <path
        d="M11.9792 17.7084C11.1167 17.7084 10.4167 18.4084 10.4167 19.2709C10.4167 20.1334 11.1167 20.8334 11.9792 20.8334C12.8417 20.8334 13.5417 20.1334 13.5417 19.2709C13.5417 18.4084 12.8417 17.7084 11.9792 17.7084Z"
        fill="#35C1F1"
      />
      <path
        d="M28.123 12.5H18.9334C18.474 12.5 18.0272 12.6521 17.6636 12.9323L10.948 18.0969L12.772 20.6156L19.1751 16.8698L27.2199 17.326L28.123 12.5Z"
        fill="#35C1F1"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9639_65047"
          x1="30.2083"
          y1="8.33329"
          x2="38.5416"
          y2="8.33329"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFCF54" />
          <stop offset="0.261" stopColor="#FDCB4D" />
          <stop offset="0.639" stopColor="#F7C13A" />
          <stop offset="1" stopColor="#F0B421" />
        </linearGradient>
      </defs>
    </svg>
  );
};
