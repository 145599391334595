export const Function_3_Icon = () => {
  return (
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.2818 28.08C33.1319 29.637 33.4943 31.5232 33.5638 33.4242C33.6101 34.6832 33.7956 35.9258 33.896 37.1778C33.9887 38.331 34.0358 39.4873 34.1533 40.6397C34.3395 42.4643 34.4353 44.2968 34.6277 46.1223C34.7397 47.1875 34.769 48.2668 34.8571 49.3398C35.0024 51.1015 35.1159 52.8655 35.3145 54.624C35.4165 55.5243 35.4706 56.465 35.323 57.3498C35.1785 58.2166 34.5805 58.9325 33.7755 59.3278C32.3006 60.0537 30.9748 59.6686 29.9936 58.5893C29.5246 58.0726 29.2874 57.4517 29.2341 56.7576C29.1352 55.4722 29.0796 54.1813 28.9274 52.902C28.7088 51.0633 28.6732 49.2114 28.4577 47.3727C28.2985 46.0095 28.3356 44.6291 28.1448 43.2658C27.8929 41.4637 27.8968 39.639 27.6874 37.8299C27.5622 36.7506 27.5499 35.6504 27.4517 34.5618C27.3923 33.9004 27.3861 33.8724 26.7294 33.8708C25.6663 33.8677 25.6191 33.8653 25.5604 34.9493C25.4607 36.7833 25.2035 38.6033 25.1563 40.4428C25.1293 41.4808 24.9624 42.5149 24.8566 43.5506C24.7368 44.7186 24.7028 45.892 24.5916 47.0615C24.4201 48.8714 24.2972 50.6852 24.1218 52.4951C24.0183 53.5619 24.0283 54.6442 23.8738 55.7079C23.7595 56.4946 23.8514 57.2945 23.4257 58.0617C22.769 59.2468 21.0461 60.0226 19.8007 59.548C18.9361 59.218 18.2879 58.692 17.8931 57.8812C17.5671 57.212 17.5594 56.4635 17.6799 55.7445C17.8236 54.8894 17.7139 54.028 17.8429 53.1705C18.1651 51.0299 18.2045 48.8597 18.4069 46.7051C18.5352 45.3411 18.6348 43.9692 18.7468 42.6005C18.9462 40.1627 19.1355 37.7248 19.3248 35.2862C19.4321 33.9019 19.5048 32.5138 19.6415 31.1326C19.837 29.1616 19.7018 27.1891 19.735 25.2173C19.7412 24.8555 19.7929 24.6828 20.2132 24.6952C21.1041 24.7209 21.8326 24.2237 22.5581 23.8035C23.7286 23.125 24.9153 22.4916 26.255 22.2613C26.8097 22.1655 27.3637 22.1547 27.947 22.4713C29.0302 23.0596 30.1172 23.6463 31.2143 24.2104C31.7366 24.4789 32.2851 24.8073 32.9333 24.7116C33.2177 24.6695 33.2918 24.8135 33.2872 25.0866C33.2725 25.9659 33.2818 26.8459 33.2818 28.0808V28.08Z"
        fill="#A52D2E"
      />
      <path
        d="M48.9111 32.0079C48.9351 34.4419 49.2093 36.8603 49.3499 39.2857C49.4674 41.301 49.7154 43.3023 49.8305 45.3184C49.9317 47.0964 50.0615 48.8807 50.2523 50.6571C50.3597 51.6585 50.4949 52.6584 50.5042 53.6739C50.5119 54.5298 50.6263 55.3888 50.7391 56.2393C50.8928 57.3948 50.7221 58.4266 49.7988 59.2296C48.7126 60.1743 46.8235 59.9976 45.9466 58.7721C45.5294 58.1885 45.4367 57.5318 45.3734 56.868C45.2405 55.4698 45.1887 54.0637 45.0419 52.667C44.8225 50.5793 44.695 48.4838 44.5135 46.3938C44.3806 44.864 44.1874 43.3319 44.1658 41.792C44.148 40.5478 43.9309 39.323 43.8367 38.0858C43.7942 37.531 43.6937 37.5294 42.7697 37.5396C42.3185 37.545 42.3193 37.8477 42.2861 38.1333C42.089 39.8101 42.0296 41.4986 41.8704 43.1778C41.6726 45.2678 41.5026 47.3625 41.3412 49.4541C41.2137 51.1014 41.019 52.7471 40.9757 54.4053C40.944 55.6207 40.8598 56.8665 40.5547 58.0344C40.2418 59.2335 39.164 59.8942 37.8683 59.828C37.2325 59.7953 36.7218 59.53 36.2381 59.1355C36.0373 58.9721 35.9971 58.8375 36.1114 58.6453C36.8508 57.4073 36.6947 56.0666 36.5132 54.7438C36.4066 53.9688 36.4576 53.193 36.3764 52.4172C36.2389 51.103 36.0118 49.7926 36.0527 48.4612C36.0913 47.2015 36.276 45.9549 36.3988 44.7029C36.4746 43.9271 36.446 43.1506 36.5387 42.374C36.7975 40.2233 36.8802 38.0562 37.1035 35.9001C37.2757 34.238 37.2271 32.5534 37.2023 30.8781C37.2008 30.7621 37.1993 30.6454 37.2023 30.5295C37.2108 30.2478 37.1382 29.9218 37.2557 29.6969C37.3854 29.4495 37.7432 29.6572 37.9834 29.5794C38.6363 29.3685 39.2536 29.0721 39.84 28.7172C40.6002 28.2566 41.403 27.894 42.2382 27.6014C42.8137 27.3999 43.4226 27.4489 43.9966 27.5259C44.3188 27.5695 44.627 27.8582 44.9554 28.0185C45.6948 28.3811 46.3893 28.841 47.1372 29.1826C47.5838 29.3864 48.0388 29.6642 48.575 29.5864C48.8825 29.5421 48.9219 29.7109 48.9173 29.9669C48.9049 30.647 48.9134 31.3278 48.9134 32.0087L48.9111 32.0079Z"
        fill="#A52D2E"
      />
      <path
        d="M15.8151 32.0289C15.8143 34.7134 16.1118 37.3769 16.3173 40.0482C16.4455 41.7118 16.6426 43.3739 16.682 45.0484C16.7075 46.137 16.845 47.2263 16.9786 48.3095C17.076 49.0977 16.9277 49.8657 16.8257 50.6267C16.6711 51.7791 16.7059 52.9393 16.5761 54.0909C16.4803 54.9437 16.3412 55.7966 16.3675 56.6618C16.3899 57.3831 16.58 58.0547 16.9516 58.6655C17.0451 58.8195 17.0945 58.9075 16.9485 59.039C15.751 60.1159 14.1517 60.1198 13.0137 58.9884C12.6065 58.5838 12.4056 58.0305 12.3415 57.4788C12.2403 56.6105 12.0865 55.7429 12.1182 54.8605C12.1483 54.0131 12.0023 53.1751 11.8965 52.3425C11.7852 51.4671 11.8756 50.5902 11.735 49.7163C11.3974 47.6092 11.4213 45.467 11.171 43.3474C11.0489 42.3172 11.0644 41.2675 10.9423 40.2303C10.8496 39.442 10.8017 38.6484 10.7337 37.8578C10.7237 37.745 10.7414 37.5901 10.5892 37.5823C10.1952 37.5637 9.79963 37.5598 9.40561 37.573C9.22482 37.5792 9.24413 37.7559 9.23023 37.8819C9.13597 38.7363 8.9884 39.5899 8.96831 40.4458C8.94127 41.5756 8.7883 42.6899 8.684 43.8104C8.61678 44.5356 8.66854 45.2655 8.56424 45.9915C8.26061 48.1048 8.24748 50.2447 8.01106 52.3666C7.89054 53.4474 7.8944 54.5446 7.77001 55.6308C7.6858 56.37 7.66957 57.1178 7.556 57.8516C7.37985 58.983 5.8725 59.9735 4.72751 59.8327C3.65823 59.7012 2.94126 59.1487 2.47306 58.2072C2.37571 58.0119 2.33013 57.815 2.32317 57.5925C2.27914 56.1304 2.49778 54.6877 2.58895 53.2365C2.67394 51.8818 2.80605 50.5334 2.92117 49.1825C3.13132 46.7112 3.31288 44.2376 3.5068 41.7647C3.642 40.035 3.75326 38.3037 3.92014 36.577C4.12952 34.4107 4.10247 32.2398 4.09011 30.0696C4.08779 29.6766 4.1975 29.5747 4.58226 29.5755C5.38422 29.5778 6.04016 29.125 6.69223 28.7343C7.43007 28.2932 8.20499 27.9049 8.97913 27.5742C9.61267 27.3034 10.3899 27.4504 11.0937 27.7096C12.1174 28.0862 13.0353 28.6604 13.9918 29.1662C14.426 29.3957 14.8949 29.6533 15.4311 29.5864C15.7456 29.5475 15.8244 29.6977 15.819 29.9879C15.8051 30.668 15.8143 31.3488 15.8159 32.0297L15.8151 32.0289Z"
        fill="#A52D2E"
      />
      <path
        d="M28.9683 22.3796C29.3507 22.1633 29.7617 22.1454 30.1349 21.8855C31.0288 21.2646 31.3039 20.4367 31.2289 19.4142C31.2204 19.2936 31.0937 19.1434 31.2312 19.0532C31.3649 18.966 31.4522 19.1216 31.5619 19.1862C31.9413 19.4095 32.3361 19.6041 32.7247 19.8118C32.8668 19.8881 32.9804 19.9332 33.0832 19.7356C33.189 19.5325 33.1148 19.4267 32.9464 19.3418C32.2959 19.0119 31.6809 18.6182 31.0141 18.3132C30.685 18.1622 30.4208 17.8658 30.1287 17.6331C29.4998 17.1328 28.7265 17.1966 28.0087 17.1499C26.6644 17.0627 25.3456 17.195 24.0221 17.5724C22.5936 17.9801 21.3334 18.6999 20.0301 19.3527C19.9149 19.4103 19.7937 19.4874 19.8964 19.6617C19.9891 19.8181 20.0672 19.9293 20.2812 19.8142C20.8629 19.5006 21.4555 19.2065 22.0435 18.9045C23.4658 18.1739 24.9747 17.728 26.567 17.6494C27.5444 17.6012 28.5256 17.5763 29.4906 17.9187C30.4339 18.2533 31.0574 19.5286 30.6356 20.4546C30.1372 21.5478 29.3268 21.9096 28.1308 21.7563C27.009 21.6132 25.8964 21.705 24.8001 22.1166C23.5562 22.5835 22.4753 23.353 21.2778 23.8892C20.2433 24.3522 19.1995 24.3996 18.1696 23.7133C17.0695 22.9811 16.371 21.9602 16.0272 20.7401C15.7537 19.7675 15.7097 18.7442 15.9021 17.7039C16.1617 16.2986 16.8021 15.1205 17.8227 14.1923C18.6324 13.4562 19.6322 13.0025 20.7463 12.8539C21.1218 12.8041 21.4918 12.752 21.8712 12.752C24.9531 12.7558 28.0342 12.7504 31.1161 12.7566C33.3381 12.7605 35.1414 13.6343 36.3397 15.5353C37.12 16.7741 37.3286 18.1856 37.2019 19.6562C37.127 20.5277 36.862 21.3276 36.4332 22.0614C35.6729 23.3608 34.0775 24.7116 32.2634 24.0666C31.5774 23.8223 31.0002 23.4153 30.3551 23.1212C29.8861 22.9072 29.4751 22.5788 28.9675 22.3796H28.9683Z"
        fill="#A52D2E"
      />
      <path
        d="M45.1004 27.5415C46.5235 27.3711 47.1038 26.5502 47.1664 24.7061C47.538 24.8912 47.9104 25.0391 48.2295 25.2912C48.3778 25.4087 48.5501 25.5161 48.6868 25.3239C48.8375 25.1122 48.649 25.0173 48.4867 24.9185C48.1143 24.692 47.7373 24.4781 47.3541 24.2711C47.0512 24.1077 46.7267 23.9949 46.4733 23.7031C46.0808 23.251 45.4883 23.1864 44.9189 23.1062C43.9825 22.974 43.0646 23.0471 42.1282 23.1778C40.4625 23.4113 38.9976 24.0968 37.59 24.9628C37.4725 25.0352 37.2724 25.0375 37.4123 25.2624C37.5305 25.4523 37.6788 25.4336 37.831 25.327C38.2312 25.0476 38.6855 24.8726 39.1089 24.6422C40.9415 23.647 42.8792 23.3264 44.932 23.6003C46.0299 23.7466 46.5081 24.1201 46.6935 25.0897C46.8859 26.0966 46.0608 27.0871 45.1035 27.0817C44.1725 27.0762 43.2562 26.9595 42.3175 27.1711C41.3286 27.3945 40.4602 27.8411 39.6049 28.3375C39.0108 28.683 38.402 29.0044 37.7391 29.1515C36.8405 29.3514 36.0579 28.9873 35.395 28.4021C35.2567 28.28 35.0937 28.171 34.9616 28.024C34.5907 27.6108 34.5815 27.1322 34.5189 26.6163C34.403 25.6624 34.7035 25.1146 35.5882 24.6742C36.7115 24.1147 37.3721 23.0728 37.8581 21.904C38.1656 21.1641 38.3324 20.4069 38.4506 19.6288C38.49 19.372 38.6121 19.3191 38.8454 19.3199C41.8933 19.3269 44.9451 19.2429 47.9884 19.3619C49.4563 19.4195 50.6222 20.291 51.4628 21.5531C52.3389 22.8681 52.5182 24.3481 52.2284 25.8118C51.9341 27.2995 51.1074 28.5204 49.5753 29.0845C48.8329 29.3584 48.1383 29.1398 47.4901 28.8464C46.6974 28.4877 45.9549 28.0138 45.0989 27.5392L45.1004 27.5415Z"
        fill="#A52D2E"
      />
      <path
        d="M5.86094 24.6695C5.84085 26.4825 6.54083 27.4357 7.90292 27.5501C6.70307 28.1073 5.68015 29.0791 4.28947 29.1795C3.12284 29.2643 2.29693 28.5819 1.64872 27.7057C0.622704 26.3191 0.50295 24.7209 0.873026 23.1086C1.07236 22.2395 1.54287 21.4629 2.19186 20.7875C2.81766 20.1362 3.564 19.7744 4.37754 19.481C4.69122 19.3682 5.00567 19.3223 5.33866 19.3231C8.22278 19.3293 11.1061 19.334 13.9902 19.3153C14.426 19.3122 14.5627 19.3806 14.6176 19.8794C14.7489 21.0598 15.143 22.1811 15.8352 23.1639C16.4239 24.0003 17.1316 24.7014 18.1213 25.0609C18.3578 25.1465 18.5046 25.3278 18.476 25.6305C18.4451 25.9596 18.4682 26.2935 18.4675 26.6257C18.4652 27.6707 17.8648 28.4426 16.9346 28.9017C15.6498 29.5351 14.5859 28.999 13.5406 28.3812C12.1708 27.5711 10.7492 26.9759 9.13059 27.0023C8.78832 27.0078 8.44683 27.0677 8.10457 27.0988C7.32193 27.1696 6.50065 26.5308 6.30827 25.5519C6.17693 24.8843 6.71466 23.8797 7.44941 23.6868C8.35489 23.4487 9.30288 23.4736 10.2393 23.5304C11.6624 23.6167 12.9496 24.1669 14.2267 24.7372C14.5635 24.8874 14.8579 25.1341 15.1824 25.3177C15.3268 25.3994 15.5223 25.5527 15.6482 25.3161C15.7471 25.1302 15.5857 25.043 15.4327 24.9489C14.0513 24.1031 12.6104 23.4603 10.9887 23.1833C9.77803 22.9771 8.60985 23.0425 7.4185 23.2067C7.06465 23.2557 6.78033 23.4837 6.59491 23.6766C6.10199 24.1879 5.41824 24.3155 4.87973 24.7092C4.76075 24.7964 4.62787 24.8633 4.50116 24.9388C4.34896 25.029 4.19907 25.1496 4.34201 25.3263C4.45635 25.4679 4.64873 25.4469 4.78084 25.3177C5.07829 25.0275 5.49009 24.9528 5.86171 24.668L5.86094 24.6695Z"
        fill="#A52D2E"
      />
      <path
        d="M20.9201 5.66474C20.7756 2.52347 23.557 0.00858865 26.4264 2.93427e-05C29.7533 -0.0100862 32.1468 2.59661 32.1707 5.64295C32.1947 8.75154 29.6907 11.3419 26.4983 11.3637C23.6103 11.3831 20.744 8.79667 20.9209 5.66474H20.9201Z"
        fill="#A52D2E"
      />
      <path
        d="M43.0874 18.033C40.2358 18.0836 38.1513 15.7508 38.1691 13.1207C38.1868 10.4751 40.2667 8.27147 43.0573 8.26758C45.9553 8.26447 47.9479 10.528 47.961 13.1363C47.9741 15.7788 45.8982 18.0695 43.0882 18.033H43.0874Z"
        fill="#A52D2E"
      />
      <path
        d="M5.09809 13.1408C5.04942 10.5053 7.30927 8.26979 9.89981 8.25811C12.7708 8.24489 14.8344 10.51 14.8568 13.1237C14.8792 15.746 12.7314 18.0515 9.94152 18.0523C7.26987 18.0523 5.03242 15.7094 5.09809 13.1408Z"
        fill="#A52D2E"
      />
    </svg>
  );
};
