import { FC } from "react";

export const GoodSuppliersIcon: FC = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6666 11.9959C16.6666 15.1876 14.1291 17.7251 10.9374 17.7251C7.74575 17.7251 5.20825 15.1876 5.20825 11.9959C5.20825 8.80422 7.74575 6.26672 10.9374 6.26672C14.1291 6.26672 16.6666 8.80422 16.6666 11.9959Z"
        fill="#5EC08F"
      />
      <path
        d="M16.5845 11.0958C15.3563 11.0958 14.1293 10.6052 13.2293 9.70416C12.3293 8.80416 11.8376 7.57603 11.8376 6.34895C11.2647 6.26666 10.7741 6.26666 10.2011 6.34895C10.2011 7.98541 10.8563 9.62291 12.0834 10.85C13.3105 12.0771 14.948 12.7323 16.5845 12.7323C16.6668 12.2417 16.6668 11.6687 16.5845 11.0958ZM9.79176 13.1417C8.56364 11.9135 6.92718 11.2594 5.29072 11.2594C5.20843 11.8323 5.20843 12.3229 5.29072 12.8958C6.51885 12.8958 7.66468 13.3864 8.64593 14.3687C9.62822 15.351 10.0376 16.4969 10.1188 17.7239C10.6918 17.8062 11.1824 17.8062 11.7553 17.7239C11.6741 16.0062 11.0199 14.3698 9.79176 13.1417Z"
        fill="#BBFFD6"
      />
      <path
        d="M45.8334 16.1458C45.8334 23.3073 39.974 29.1667 32.8126 29.1667C25.6511 29.1667 19.7917 23.3073 19.7917 16.1458C19.7917 8.98438 25.6511 3.125 32.8126 3.125C39.974 3.125 45.8334 8.98438 45.8334 16.1458Z"
        fill="#E0DDDC"
      />
      <path
        d="M22.8511 13.2947L23.2625 12.0593L28.3886 13.7687L27.9771 15.0041L22.8511 13.2947Z"
        fill="#BFBFBF"
      />
      <path
        d="M32.1614 5.72913H33.4635V10.9375H32.1614V5.72913Z"
        fill="#BFBFBF"
      />
      <path
        d="M26.0532 24.1343L29.3751 19.151L30.4584 19.8729L27.1366 24.8562L26.0532 24.1343Z"
        fill="#BFBFBF"
      />
      <path
        d="M35.1667 19.874L36.2501 19.1521L39.572 24.1354L38.4886 24.8573L35.1667 19.874Z"
        fill="#BFBFBF"
      />
      <path
        d="M35.2708 14.4229L42.3739 12.0583L42.7853 13.2938L35.6822 15.6583L35.2708 14.4229Z"
        fill="#BFBFBF"
      />
      <path
        d="M27.6042 14.1927L32.8126 10.2865L38.0209 14.1927L36.0678 20.0521H29.5574L27.6042 14.1927ZM36.7188 3.71146C35.4824 3.32083 34.1803 3.125 32.8126 3.125C31.4449 3.125 30.1428 3.32083 28.9063 3.71146L27.8647 5.6L32.8126 7.03125L37.3699 5.72917L36.7188 3.71146ZM44.5313 17.4479L45.8334 16.1458C45.8334 12.8906 44.6615 9.96042 42.7084 7.68229L41.2761 7.87813V13.1521L44.5313 17.4479ZM38.1511 23.1771L35.3511 27.6042L36.849 28.5156C39.7792 27.5385 42.2532 25.5854 43.8157 23.0469L43.2949 21.1583L38.1511 23.1771ZM24.4147 13.151L24.349 7.87708L22.9167 7.68229C20.9636 9.96146 19.7917 12.8906 19.7917 16.1458L21.0938 17.4479L24.4147 13.151ZM22.3313 21.224L21.8105 23.1125C23.4386 25.6521 25.847 27.6052 28.7772 28.5812L30.2095 27.5396L27.5397 23.2427L22.3313 21.224Z"
        fill="#546E7A"
      />
      <path
        d="M5.08949 28.2541L5.92907 26.3698L29.7468 36.9802L28.9072 38.8646L5.08949 28.2541ZM7.71032 24.9062L8.5499 23.0219L30.4468 32.6958L29.6072 34.5802L7.71032 24.9062ZM27.127 40.3291L26.2874 42.2135L4.35303 32.4427L5.19261 30.5583L27.127 40.3291Z"
        fill="#D6D1D0"
      />
      <path
        d="M18.178 22.8882L10.5652 39.9788L12.4492 40.818L20.062 23.7274L18.178 22.8882Z"
        fill="#D6D1D0"
      />
      <path
        d="M14.0115 21.8465L6.39868 38.937L8.28272 39.7762L15.8955 22.6857L14.0115 21.8465Z"
        fill="#D6D1D0"
      />
      <path
        d="M21.9458 24.5663L14.333 41.6569L16.217 42.4961L23.8298 25.4055L21.9458 24.5663Z"
        fill="#D6D1D0"
      />
      <path
        d="M27.6949 27.0479L19.9845 44.175L18.1001 43.3365L25.7136 26.2458L27.6949 27.0479Z"
        fill="#D6D1D0"
      />
      <path
        d="M39.3761 41.4313L38.5365 43.3156C33.0188 40.8584 31.4449 37.3344 29.8469 34.0417C28.2115 30.6521 26.6365 27.1282 20.8584 23.9896L21.8324 22.1656C28.1865 25.6417 30.0449 29.6136 31.7178 33.1011C33.3521 36.4896 34.6657 39.3334 39.3761 41.4313Z"
        fill="#C93D18"
      />
      <path
        d="M39.376 41.4312L38.5365 43.3156C33.6917 41.1573 30.7 42.0823 27.3187 43.1562C23.6083 44.2448 19.3146 45.5573 12.5781 43.1208L13.2823 41.1771C19.4802 43.3739 23.1906 42.2854 26.7667 41.1364C30.2833 40.1218 33.9562 38.9364 39.376 41.4312Z"
        fill="#C93D18"
      />
      <path
        d="M5.99053 32.9292C5.50512 31.6646 5.05095 29.6073 5.98741 27.6865C6.82699 25.8021 8.74262 24.3979 11.2853 23.7573C14.1582 23.1021 17.4405 23.5156 20.5353 24.8948C24.1687 26.5136 26.9937 29.2229 28.1499 32.2375C28.6353 33.5021 29.0895 35.5594 28.153 37.4802C27.3134 39.3646 25.3978 40.7688 22.8551 41.4094C19.9832 42.0646 16.6999 41.6511 13.6051 40.2719C10.0093 38.7511 7.14678 35.9438 5.99053 32.9292ZM3.07283 34.0479C4.49053 37.7438 7.80824 41.1573 12.2864 43.2323C19.9562 46.649 28.3416 44.6594 30.9197 38.8729C31.9989 36.4511 31.8812 33.8177 30.9114 31.2896C29.4937 27.5938 26.1759 24.1802 21.6978 22.1052C14.028 18.6886 5.64262 20.6781 3.06449 26.4646C2.04574 28.7521 2.10304 31.5198 3.07283 34.0479Z"
        fill="#FF754C"
      />
      <path
        d="M48.1991 44.0709L48.9449 46.0157L48.3459 47.3615L46.4012 48.1073L38.3272 44.5105L37.7678 43.0521L38.6668 41.0334L40.1251 40.474L48.1991 44.0709Z"
        fill="#37474F"
      />
      <path
        d="M39.5376 45.0511L41.422 45.8907L43.2199 41.8542L41.3355 41.0146L39.5376 45.0511ZM47.0251 43.6292L45.1407 42.7896L43.3428 46.8261L45.2272 47.6657L47.0251 43.6292Z"
        fill="#546E7A"
      />
    </svg>
  );
};
