import { FC } from "react";

export const ServicePartnersIcon: FC = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.0002 36.9792C31.6161 36.9792 36.9793 31.616 36.9793 25C36.9793 18.3841 31.6161 13.0209 25.0002 13.0209C18.3843 13.0209 13.021 18.3841 13.021 25C13.021 31.616 18.3843 36.9792 25.0002 36.9792Z"
        fill="url(#paint0_linear_9639_65127)"
      />
      <path
        d="M25.0002 32.2917C29.0272 32.2917 32.2918 29.0271 32.2918 25C32.2918 20.973 29.0272 17.7084 25.0002 17.7084C20.9731 17.7084 17.7085 20.973 17.7085 25C17.7085 29.0271 20.9731 32.2917 25.0002 32.2917Z"
        fill="url(#paint1_linear_9639_65127)"
      />
      <path
        d="M45.2155 20.0448C42.7269 20.0146 40.3175 18.7177 38.9832 16.4063C37.6988 14.1813 37.7248 11.5636 38.8144 9.4344C36.6009 7.46877 33.979 5.95627 31.0748 5.0719C29.7675 7.03752 27.5363 8.33336 24.9998 8.33336C22.4634 8.33336 20.2321 7.03752 18.9259 5.0719C16.0217 5.95627 13.3998 7.46877 11.1863 9.4344C12.2759 11.5636 12.3019 14.1813 11.0175 16.4063C9.68317 18.7177 7.2738 20.0146 4.78525 20.0448C4.39567 21.6354 4.1665 23.2896 4.1665 25C4.1665 26.3375 4.30505 27.6406 4.54567 28.9084C7.11755 28.8552 9.64046 30.1667 11.0175 32.5521C12.4425 35.0209 12.254 37.9729 10.7884 40.2032C12.9571 42.2313 15.5519 43.8032 18.4342 44.7604C19.6113 42.3167 22.105 40.625 24.9998 40.625C27.8946 40.625 30.3884 42.3167 31.5655 44.7615C34.4478 43.8042 37.0425 42.2323 39.2113 40.2042C37.7457 37.974 37.5571 35.0219 38.9821 32.5531C40.3592 30.1677 42.8811 28.8563 45.454 28.9094C45.6946 27.6406 45.8332 26.3375 45.8332 25C45.8332 23.2896 45.604 21.6354 45.2155 20.0448ZM24.9998 35.9375C18.9592 35.9375 14.0623 31.0406 14.0623 25C14.0623 18.9594 18.9592 14.0625 24.9998 14.0625C31.0405 14.0625 35.9373 18.9594 35.9373 25C35.9373 31.0406 31.0405 35.9375 24.9998 35.9375Z"
        fill="url(#paint2_linear_9639_65127)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9639_65127"
          x1="33.346"
          y1="33.3459"
          x2="16.5429"
          y2="16.5427"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.242" stopColor="#F2F2F2" />
          <stop offset="1" stopColor="#CCCCCC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9639_65127"
          x1="18.1772"
          y1="18.1771"
          x2="30.146"
          y2="30.1459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0D61A9" />
          <stop offset="0.363" stopColor="#0E5FA4" />
          <stop offset="0.78" stopColor="#135796" />
          <stop offset="1" stopColor="#16528C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9639_65127"
          x1="5.54775"
          y1="5.56669"
          x2="39.6686"
          y2="39.6865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#889097" />
          <stop offset="0.331" stopColor="#848C94" />
          <stop offset="0.669" stopColor="#78828B" />
          <stop offset="1" stopColor="#64717C" />
        </linearGradient>
      </defs>
    </svg>
  );
};
