import { FC } from "react";
import classes from "./OurMissionCard.module.scss";

interface IOurMissionCardProps {
  icon: FC;
  title: string;
  description: string;
}

export const OurMissionCard: FC<IOurMissionCardProps> = ({
  icon: Icon,
  title,
  description,
}) => {
  return (
    <div className={classes.OurMissionCard}>
      <div className={classes.Icon}><Icon /></div>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
};
