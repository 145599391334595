import {
  AthletesIcon,
  CorporativesIcon,
  EventOrganizersIcon,
  GoodSuppliersIcon,
  ServicePartnersIcon,
  TrainersIcon,
} from "assets";

export const aboutUsCardsFirstColumn = [
  {
    icon: AthletesIcon,
    title: "Спортсмены любители",
    description:
      "<ul><li>Трекеры активности</li><li>План тренировок</li><li>Программа лояльности</li></ul>",
  },
  {
    icon: GoodSuppliersIcon,
    title: "Поставщики спорттоваров и комплементарных продуктов",
    description:
      "<ul><li>Проведение челленджей</li><li>Возможность ведение профильного контента</li></ul>",
  },
  {
    icon: TrainersIcon,
    title: "Тренеры",
    description:
      "<p>Для каждой корпорации и РоИвы в площадке предусмотрена своя система администрирования </p>",
  },
];
export const aboutUsCardsSecondColumn = [
  {
    icon: CorporativesIcon,
    title: "Корпорации РоИвы",
    description:
      "<ul><li>Статистика</li><li>Аналитика</li><li>Рейтинги</li></ul>",
  },
  {
    icon: ServicePartnersIcon,
    title: "Сервисные партнеры (ЛПУ и СК)",
    description:
      "<ul><li>Дистанционные консультации</li><li>Чекаппы и получение справок</li><li>Запись на приём</li></ul>",
  },
  {
    icon: EventOrganizersIcon,
    title: "Организаторы мероприятий",
    description:
      "<ul><li>Календарь и анонс спортивных мероприятий</li><li>Запись и продажа слотов</li></ul>",
  },
];
