export const Function_2_Icon = () => {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11132_13103)">
        <path
          d="M8.34456 18.2561C8.3341 17.756 8.377 17.3205 8.43664 16.8829C8.53708 16.141 8.36444 15.4298 8.09554 14.735C7.91348 14.2647 7.77642 13.7789 7.63203 13.2942C7.46671 12.7388 7.61005 12.2623 7.87582 11.8001C8.15413 11.3164 8.43664 10.8307 8.77041 10.3849C9.29565 9.68397 9.41807 8.87236 9.5499 8.05255C9.61896 7.62113 9.70894 7.1938 9.80416 6.7675C9.92657 6.21925 10.2719 5.82267 10.773 5.58288C12.2577 4.87374 13.5018 3.90842 14.3734 2.50757C14.9603 1.56376 15.9229 1.27171 16.9588 1.32602C18.3996 1.40185 19.6855 1.02781 20.9128 0.337125C21.686 -0.0973734 22.4676 -0.103522 23.2921 0.27564C24.0109 0.606638 24.6878 1.03909 25.4809 1.21022C26.2866 1.38443 27.108 1.29733 27.9209 1.37931C28.6042 1.44797 29.2309 1.6068 29.6097 2.18067C30.6152 3.70244 31.9565 4.82148 33.6013 5.63617C34.5116 6.08706 34.589 7.02062 34.6937 7.87322C34.8203 8.91028 35.0442 9.90122 35.7358 10.7241C36.7329 11.9108 36.9516 13.1723 36.2349 14.57C35.6427 15.726 35.8132 16.9557 35.9367 18.171C36.0256 19.0421 35.832 19.7902 35.1206 20.3671C35.018 20.4501 34.9375 20.5598 34.8339 20.6407C33.8033 21.4482 33.0667 22.4556 32.6858 23.6986C32.3342 24.8463 31.5296 25.5104 30.3525 25.7174C29.0436 25.948 27.9366 26.5259 27.0044 27.438C26.1066 28.3152 25.052 28.5027 23.8811 28.1184C22.4917 27.6614 21.1346 27.9329 19.7702 28.2332C19.0587 28.39 18.3567 28.3592 17.7958 27.8407C16.5078 26.6468 15.0095 25.8762 13.2716 25.5135C12.5821 25.37 12.1081 24.9037 11.8413 24.254C11.8099 24.1782 11.7628 24.1085 11.7388 24.0317C11.2658 22.555 10.3932 21.3581 9.16382 20.3856C8.46489 19.8322 8.17401 19.0872 8.34561 18.2571L8.34456 18.2561ZM22.1129 23.9917C27.5893 24.0573 32.1396 19.6641 32.2035 14.2595C32.2662 8.87543 27.946 4.28655 22.16 4.26606C16.4597 4.24659 12.0861 8.6961 12.06 14.113C12.0328 19.7348 16.7903 24.0634 22.1139 23.9917H22.1129Z"
          fill="#A52D2E"
        />
        <path
          d="M33.5222 24.3164C36.7051 28.1664 39.8084 31.9201 42.9997 35.7804C42.3143 35.6851 41.7535 35.6144 41.1948 35.5293C39.3282 35.2455 37.4637 34.9575 35.5981 34.6696C35.4024 34.6399 35.2078 34.5978 35.0101 34.5743C34.0998 34.4615 34.105 34.4656 33.7305 35.2977C32.6423 37.7152 31.551 40.1315 30.4597 42.5469C30.4063 42.6648 30.3457 42.7795 30.2358 42.9999C30.0046 42.3276 29.7963 41.7455 29.6038 41.1594C28.3629 37.3719 27.1325 33.5813 25.879 29.7968C25.7597 29.4361 25.8089 29.2947 26.1835 29.1768C26.897 28.9524 27.4589 28.4677 27.9967 27.982C28.6653 27.3794 29.4186 27.0003 30.3069 26.8045C31.7331 26.4899 33.0263 25.957 33.5222 24.3164Z"
          fill="#A52D2E"
        />
        <path
          d="M6.97424e-05 32.493C3.50205 29.6186 6.98729 26.7575 10.4882 23.884C11.0794 25.702 12.4124 26.5638 14.1942 26.8743C15.0156 27.0178 15.6622 27.4553 16.268 27.979C16.8445 28.477 17.4305 28.9658 18.166 29.2261C18.4966 29.3429 18.2811 29.5007 18.211 29.6258C17.1092 31.6025 16.0023 33.5762 14.8932 35.5499C13.8647 37.3801 12.833 39.2083 11.8013 41.0375C11.7469 41.1338 11.7239 41.2568 11.5555 41.3193C11.3211 40.354 11.0888 39.3979 10.8576 38.4418C10.4589 36.7878 10.0352 35.139 9.67839 33.4768C9.56748 32.9593 9.30068 32.8261 8.83507 32.8159C7.65065 32.7902 6.46623 32.7441 5.28182 32.7144C3.63075 32.6734 1.97863 32.6416 0.326517 32.6007C0.235488 32.5986 0.125626 32.6365 -0.000976562 32.491L6.97424e-05 32.493Z"
          fill="#A52D2E"
        />
        <path
          d="M28.6982 11.2487C28.6857 11.4034 28.5789 11.4659 28.4973 11.5448C26.1232 13.87 23.745 16.1921 21.3804 18.5265C21.1554 18.7489 21.0497 18.7305 20.8363 18.5194C19.181 16.8797 17.5163 15.2473 15.8402 13.6271C15.5755 13.372 15.4938 13.209 15.8234 12.9426C16.1698 12.6628 16.4868 12.3421 16.7808 12.008C16.9953 11.7652 17.1146 11.8051 17.3259 12.0152C18.4486 13.1363 19.6027 14.2287 20.7128 15.361C21.0424 15.6972 21.2213 15.648 21.5247 15.3467C23.2951 13.5872 25.0916 11.8533 26.8598 10.0917C27.1476 9.80582 27.3098 9.81401 27.5651 10.102C27.8706 10.4473 28.2211 10.7537 28.5507 11.0786C28.6082 11.1349 28.6564 11.1995 28.6972 11.2466L28.6982 11.2487Z"
          fill="#A52D2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_11132_13103">
          <rect width="43" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
