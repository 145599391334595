import { Function_1_Icon, Function_2_Icon, Function_3_Icon } from "assets";

export const functionTextCardsData = [
  {
    id: 1,
    icon: Function_1_Icon,
    text: 'В разделе "Челенджи" нашего приложения вас ждут захватывающие вызовы и мероприятия, спроектированные для вдохновения и мотивации. Присоединяйтесь к увлекательным соревнованиям, где вы можете соревноваться с другими участниками, устанавливать новые рекорды и достигать своих фитнес-целей. ',
  },
  {
    id: 2,
    icon: Function_2_Icon,
    text: 'В разделе "Программа нормативов" мы предлагаем вам возможность установить собственные цели и затем следить за своим прогрессом. С помощью нашей программы вы можете создавать персонализированные тренировки, устанавливать цели по времени, дистанции или интенсивности, и отслеживать свои результаты.',
  },
  {
    id: 3,
    icon: Function_3_Icon,
    text: 'В разделе "Команды" вы можете создавать и присоединяться к спортивным группам, обмениваться опытом, устанавливать коллективные цели и соревноваться в командных мероприятиях. Это идеальное место для тех, кто хочет поддерживать и развивать командный дух, а также делиться своими достижениями и мотивировать других участников. Вместе мы двигаемся к здоровому будущему!',
  },
];
