import { FC } from "react";
import classes from "./Title.module.scss";

interface ITitleProps {
  title: string;
  className?: string;
}

export const SectionTitle: FC<ITitleProps> = ({ title, className='' }) => {
  return <h2 className={`${classes.SectionTitle} ${className}`}>{title}</h2>;
};

export const SectionSubTitle: FC<ITitleProps> = ({ title, className='' }) => {
  return <h3 className={`${classes.SectionSubtitle} ${className}`}>{title}</h3>;
};
