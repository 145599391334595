import { FC } from "react";

export const TrainersIcon: FC = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 4.20935C23.3424 4.20935 21.7527 4.86575 20.5806 6.03414C19.4085 7.20253 18.75 8.78721 18.75 10.4396C18.75 12.0919 19.4085 13.6766 20.5806 14.845C21.7527 16.0134 23.3424 16.6698 25 16.6698C26.6576 16.6698 28.2473 16.0134 29.4194 14.845C30.5915 13.6766 31.25 12.0919 31.25 10.4396C31.25 8.78721 30.5915 7.20253 29.4194 6.03414C28.2473 4.86575 26.6576 4.20935 25 4.20935Z"
        fill="#FFB74D"
      />
      <path
        d="M14.5833 29.1667L16.6666 43.75V45.8334H33.3333V43.75L35.4166 29.1667V23.9584H14.5833V29.1667Z"
        fill="#2196F3"
      />
      <path
        d="M31.2499 33.3333L30.2083 43.75V45.8333H33.3333V43.75L35.3426 29.6875C35.3426 29.6875 34.3749 32.5521 31.2499 33.3333ZM18.7499 33.3333L19.7916 43.75V45.8333H16.6666V43.75L14.6572 29.6875C14.6572 29.6875 15.6249 32.5521 18.7499 33.3333Z"
        fill="#1976D2"
      />
      <path
        d="M33.0541 42.9875C33.5624 43.4958 34.2291 43.75 34.8958 43.75C35.5624 43.75 36.2291 43.4958 36.7374 42.9875L38.5416 41.1823L34.8593 37.5L33.0551 39.3042C32.0374 40.3219 32.0374 41.9698 33.0541 42.9875ZM16.9458 42.9875C16.4374 43.4958 15.7708 43.75 15.1041 43.75C14.4374 43.75 13.7708 43.4958 13.2624 42.9875L11.4583 41.1823L15.1405 37.5L16.9447 39.3042C17.9624 40.3219 17.9624 41.9698 16.9458 42.9875Z"
        fill="#FFB74D"
      />
      <path
        d="M44.1864 32.1855L37.7843 24.5125C36.1229 22.2063 33.4468 20.8334 30.5999 20.8334H24.9999H19.3999C16.5531 20.8334 13.877 22.2063 12.2156 24.5125L5.81348 32.1855C4.94994 33.2198 5.01869 34.7427 5.97182 35.6948L11.4583 41.1823L15.1406 37.5L11.3364 33.6959L16.2791 27.7719C16.326 27.7157 16.3708 27.6573 16.4135 27.5969C17.0947 26.624 18.2114 26.0417 19.3999 26.0417H24.9999H30.5999C31.7885 26.0417 32.9051 26.624 33.5864 27.5969C33.6291 27.6573 33.6729 27.7157 33.7208 27.7719L38.6635 33.6959L34.8593 37.5L38.5416 41.1823L44.0291 35.6948C44.9822 34.7438 45.051 33.2209 44.1864 32.1855Z"
        fill="#2196F3"
      />
      <path
        d="M30.2084 20.8334V21.875C30.2084 24.2084 29.7604 29.9896 25.6563 33.0938L25.625 33.125C25.5521 33.1771 25.4792 33.2188 25.3959 33.25C25.3438 33.2813 25.2917 33.2917 25.2292 33.3021C25.1563 33.323 25.0834 33.3334 25 33.3334C24.8334 33.3334 24.6667 33.2917 24.5209 33.2188C24.4584 33.1771 24.3959 33.1459 24.3438 33.0938C24.2813 33.0417 24.2188 32.9792 24.1667 32.9167C23.8229 32.4584 23.9167 31.8021 24.375 31.4584C24.5938 31.2917 24.8021 31.1146 25 30.9271C27.8021 28.2709 28.125 23.7709 28.125 21.875V20.8334H30.2084Z"
        fill="#BDBDBD"
      />
      <path
        d="M27.8126 32.6031L24.8105 31.2885C24.5459 31.1729 24.2366 31.2916 24.1189 31.5541L21.9199 36.4958C21.8032 36.7583 21.923 37.0656 22.1876 37.1812L25.1897 38.4958C25.4543 38.6114 25.7647 38.4927 25.8814 38.2302L28.0803 33.2885C28.197 33.0271 28.0772 32.7198 27.8126 32.6031Z"
        fill="white"
      />
      <path
        d="M25.8334 32.9167C25.7813 32.9792 25.7188 33.0417 25.6563 33.0938C25.6042 33.1459 25.5417 33.1771 25.4792 33.2188C25.4688 33.2188 25.448 33.2292 25.4376 33.2396C25.3751 33.2709 25.3022 33.2917 25.2292 33.3021C25.1563 33.323 25.0834 33.3334 25.0001 33.3334C24.9167 33.3334 24.8438 33.323 24.7709 33.3021C24.698 33.2917 24.6251 33.2709 24.5626 33.2396C24.5522 33.2292 24.5313 33.2188 24.5209 33.2188C24.4688 33.1875 24.4167 33.1563 24.3751 33.125L24.3438 33.0938C20.2397 29.9896 19.7917 24.2084 19.7917 21.875V20.8334H21.8751V21.875C21.8751 23.7709 22.198 28.2709 25.0001 30.9271C25.198 31.1146 25.4063 31.2917 25.6251 31.4584C26.0834 31.8021 26.1772 32.4584 25.8334 32.9167Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};
