import { FC } from "react";
import { BenefitCard, SectionTitle } from "components";
import {
  Benefit_1_Icon,
  Benefit_2_Icon,
  Benefit_3_Icon,
  Benefit_4_Icon,
} from "assets";
import BenefitImage1 from "assets/images/benefit_1-min.png";
import BenefitImage2 from "assets/images/benefit_2-min.png";
import BenefitImage3 from "assets/images/benefit_3-min.png";
import BenefitImage4 from "assets/images/benefit_4-min.png";
import classes from "./ProjectBenefits.module.scss";

const benefitCards = [
  {
    id: 1,
    icon: Benefit_1_Icon,
    imgSrc: BenefitImage1,
    title: "Повышение физической активности",
    description:
      "Наше приложение ставит своей целью стимулировать физическую активность и спортивные достижения внутри страны. Мы предоставляем инструменты и мотивацию, чтобы люди развивали здоровый образ жизни, занимались спортом и достигали новых вершин.",
    onClick: () => {},
  },
  {
    id: 2,
    icon: Benefit_2_Icon,
    imgSrc: BenefitImage2,
    title: "Создание активного спортивного сообщества",
    description:
      "Мы способствуем объединению спортсменов и любителей активного образа жизни внутри страны. Наши возможности для обмена опытом, участия в соревнованиях и взаимной мотивации помогают создать сильное и поддерживающее спортивное комьюнити.",
    onClick: () => {},
  },
  {
    id: 3,
    icon: Benefit_3_Icon,
    imgSrc: BenefitImage3,
    title: "Создание спортивной инфраструктуры",
    description:
      "Мы поддерживаем развитие спортивной инфраструктуры внутри страны, предоставляя информацию о спортивных мероприятиях, тренировках и местах для занятий спортом. Это способствует созданию удобных условий для спорта и активизирует интерес к нему.",
    onClick: () => {},
  },
  {
    id: 4,
    icon: Benefit_4_Icon,
    imgSrc: BenefitImage4,
    title: "Поощрение здорового образа жизни",
    description:
      "Мы вдохновляем пользователей на заботу о своем здоровье, поддерживая их в пути к здоровому будущему. Приложение предоставляет возможности для постановки и достижения фитнес-целей, создания здоровых привычек и повышения качества жизни внутри страны.",
    onClick: () => {},
  },
];

export const ProjectBenefits: FC = () => {
  return (
    <section className={classes.ProjectBenefits}>
      <SectionTitle title="Польза проекта" />
      {benefitCards.map((card, index) => (
        <BenefitCard key={card.id} {...card} isEven={!(index % 2)} />
      ))}
    </section>
  );
};
