import { FC } from "react";
import { SectionSubTitle, SectionTitle, FunctionTextCard } from "components";

import FunctionalPhone1 from "assets/images/functionalPhone1-min.png";
import FunctionalPhone2 from "assets/images/functionalPhone2-min.png";
import FunctionalPhones from "assets/images/functionalPhones-min.png";
import Activity11 from "assets/images/activityImage_1_1-min.png";
import Activity12 from "assets/images/activityImage_1_2-min.png";
import Activity13 from "assets/images/activityImage_1_3-min.png";
import Activity21 from "assets/images/activityImage_2_1-min.png";
import Activity22 from "assets/images/activityImage_2_2-min.png";
import Activity23 from "assets/images/activityImage_2_3-min.png";

import classes from "./ProjectFunctions.module.scss";
import { functionTextCardsData } from "./data";
import { CirclesSvg } from "assets";

export const ProjectFunctions: FC = () => {
  return (
    <>
      <section className={classes.ProjectFunctions}>
        <SectionTitle title="Функции" />
        <div className={classes.ChallengesBoxContainer}>
          <div className={classes.ChallengesTextBlock}>
            <SectionSubTitle title="Челленджи" />
            {functionTextCardsData.map(({ id, text, icon }) => (
              <FunctionTextCard text={text} icon={icon} key={id} />
            ))}
          </div>
          <div className={classes.ChallengesImageBlock}>
            <img src={FunctionalPhone1} alt="Challenge Phone" />
            <div className={classes.Brightness} />
            <CirclesSvg />
          </div>
        </div>
        <div className={classes.ActivitiesBoxContainer}>
          <div className={classes.ActivitiesImageBlock}>
            <img src={FunctionalPhone2} alt="Activities Phone" />
            <div className={classes.Brightness} />
            <CirclesSvg />
          </div>
          <div className={classes.ActivitiesTextBlock}>
            <SectionSubTitle title="Активность и группы по интересам" />
            <div>
              <p className={classes.Subtitle}>Удобный счетчик статистики</p>
              <div className={classes.ImagesBox}>
                <img src={Activity11} alt="Activity 1" />
                <img src={Activity12} alt="Activity 2" />
                <img src={Activity13} alt="Activity 3" />
              </div>
            </div>
            <div>
              <p className={classes.Subtitle}>
                Группы по вашим спортивным интересам
              </p>
              <div className={classes.ImagesBox}>
                <img src={Activity21} alt="Activity 1" />
                <img src={Activity22} alt="Activity 2" />
                <img src={Activity23} alt="Activity 3" />
              </div>
            </div>
            <p className={classes.Info}>
              В нашем приложении мы делаем акцент на социальной активности и
              возможности общения с людьми, которые разделяют ваши интересы.
              Предлагая множество групп и сообществ по различным тематикам, мы
              создаем пространство для вдохновения и обмена опытом.
            </p>
          </div>
        </div>
      </section>
      <section className={classes.PhonesSection}>
        <div className={classes.PhonesBox}>
          <div className={classes.RedRibbonBox}>
            <div className={classes.RedRibbon}>
              <span>ГТО</span>
              <span>ГТО</span>
              <span>ГТО</span>
            </div>
          </div>
          <img src={FunctionalPhones} alt="phones" className={classes.Phones} />
        </div>
      </section>
    </>
  );
};
