import { FC } from "react";

export const CorporativesIcon: FC = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.5832 32.2916H10.4165V44.7916H39.5832V32.2916Z"
        fill="#0078D4"
      />
      <path
        d="M14.5835 9.37504H35.4168V7.29171C35.4168 6.14171 34.4835 5.20837 33.3335 5.20837H16.6668C15.5168 5.20837 14.5835 6.14171 14.5835 7.29171V9.37504Z"
        fill="#199BE2"
      />
      <path
        d="M10.4165 11.4584V32.2917H39.5832V11.4584H10.4165Z"
        fill="#199BE2"
      />
      <path
        d="M18.7502 36.4584H14.5835V40.625H18.7502V36.4584Z"
        fill="#005094"
      />
      <path d="M28.125 36.4584H21.875V44.7917H28.125V36.4584Z" fill="#005094" />
      <path
        d="M18.7502 23.9584H14.5835V28.125H18.7502V23.9584Z"
        fill="#0D62AB"
      />
      <path
        d="M27.0832 23.9584H22.9165V28.125H27.0832V23.9584Z"
        fill="#0D62AB"
      />
      <path
        d="M18.7502 15.625H14.5835V19.7917H18.7502V15.625Z"
        fill="#0D62AB"
      />
      <path
        d="M27.0832 15.625H22.9165V19.7917H27.0832V15.625Z"
        fill="#0D62AB"
      />
      <path d="M35.4167 15.625H31.25V19.7917H35.4167V15.625Z" fill="#0D62AB" />
      <path
        d="M39.5833 11.4583H10.4167C9.84063 11.4583 9.375 10.9917 9.375 10.4167C9.375 9.84167 9.84063 9.375 10.4167 9.375H39.5833C40.1594 9.375 40.625 9.84167 40.625 10.4167C40.625 10.9917 40.1594 11.4583 39.5833 11.4583Z"
        fill="#35C1F1"
      />
      <path
        d="M36.4582 37.9469C30.705 37.9469 26.0415 41.3365 26.0415 44.8292V45.8334C26.0415 46.4084 26.5082 46.875 27.0832 46.875H45.8332C46.4082 46.875 46.8748 46.4084 46.8748 45.8334V44.8292C46.8748 41.3365 42.2113 37.9469 36.4582 37.9469Z"
        fill="url(#paint0_linear_9639_65102)"
      />
      <path
        d="M39.5835 38.3834C39.5835 43.3282 36.4585 45.8334 36.4585 45.8334C36.4585 45.8334 33.3335 43.3282 33.3335 38.3834C33.3335 36.7355 33.3335 35.8334 33.3335 35.8334H39.5835C39.5835 35.8334 39.5835 36.7344 39.5835 38.3834Z"
        fill="white"
      />
      <path
        d="M35.2085 41.4583L35.8335 42.0833L35.346 44.6125C35.9502 45.4208 36.4585 45.8333 36.4585 45.8333C36.4585 45.8333 36.9668 45.4208 37.571 44.6125L37.0835 42.0833L37.7085 41.4583L36.4585 40.625L35.2085 41.4583Z"
        fill="#D32F2F"
      />
      <path
        d="M39.5835 38.125C39.5835 39.3104 38.3335 40.625 36.4585 40.625C34.5835 40.625 33.3335 39.3104 33.3335 38.125C33.3335 36.4771 33.3335 33.7281 33.3335 33.7281H39.5835C39.5835 33.7281 39.5835 36.4771 39.5835 38.125Z"
        fill="#D6A121"
      />
      <path
        d="M41.7654 31.25H40.98V34.375H41.8706C42.1769 34.375 42.4383 34.1312 42.4904 33.7969L42.6956 32.4667C42.7925 31.8302 42.3487 31.25 41.7654 31.25Z"
        fill="url(#paint1_linear_9639_65102)"
      />
      <path
        d="M31.1511 31.25H31.9365V34.375H31.0458C30.7396 34.375 30.4781 34.1312 30.4261 33.7969L30.2208 32.4667C30.124 31.8302 30.5677 31.25 31.1511 31.25Z"
        fill="url(#paint2_linear_9639_65102)"
      />
      <path
        d="M36.4583 24.3052C33.8885 24.3052 31.25 24.6958 31.25 30.251C31.25 30.6958 31.25 33.6521 31.25 34.1271C31.25 36.1114 34.375 38.5416 36.4583 38.5416C38.5417 38.5416 41.6667 36.1114 41.6667 34.1281C41.6667 33.6531 41.6667 30.6968 41.6667 30.2521C41.6667 24.6958 39.0281 24.3052 36.4583 24.3052Z"
        fill="url(#paint3_radial_9639_65102)"
      />
      <path
        d="M36.4585 22.9166C33.972 22.9166 33.3335 23.9583 33.3335 23.9583C31.845 23.9583 30.2085 24.9427 30.2085 28.125C30.2085 29.2843 30.5064 30.9291 31.2502 33.3333C31.6054 28.7437 32.3418 26.4718 33.4825 26.4718C34.3075 26.4718 35.0481 27.0833 36.4585 27.0833C37.8689 27.0833 38.9189 26.4718 39.4345 26.4718C41.3877 26.4718 41.6668 32.6093 41.6668 33.3333C42.4106 30.9291 42.7085 29.1385 42.7085 28.125C42.7085 24.9166 40.5012 22.9166 36.4585 22.9166Z"
        fill="url(#paint4_radial_9639_65102)"
      />
      <path
        d="M36.4584 22.9166C33.748 22.9166 33.3334 23.9583 33.3334 23.9583C33.3063 24.7125 33.4824 25.2979 33.4824 25.2979C33.4824 25.2979 33.9469 27.0833 36.7719 27.0833C38.4782 27.0833 40.6251 25.7583 40.6251 22.9166C40.6251 22.9166 40.324 22.9166 36.4584 22.9166Z"
        fill="url(#paint5_radial_9639_65102)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9639_65102"
          x1="34.28"
          y1="37.6229"
          x2="37.1207"
          y2="47.3948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8795A1" />
          <stop offset="1" stopColor="#6D7882" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9639_65102"
          x1="41.4123"
          y1="32.8125"
          x2="42.5039"
          y2="32.8125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C48F0C" />
          <stop offset="0.251" stopColor="#D19B16" />
          <stop offset="0.619" stopColor="#DCA51F" />
          <stop offset="1" stopColor="#E0A922" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9639_65102"
          x1="31.5031"
          y1="32.8125"
          x2="30.4115"
          y2="32.8125"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C48F0C" />
          <stop offset="0.251" stopColor="#D19B16" />
          <stop offset="0.619" stopColor="#DCA51F" />
          <stop offset="1" stopColor="#E0A922" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_9639_65102"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.46 31.4243) scale(6.23707)"
        >
          <stop stopColor="#FFCF54" />
          <stop offset="0.261" stopColor="#FDCB4D" />
          <stop offset="0.639" stopColor="#F7C13A" />
          <stop offset="1" stopColor="#F0B421" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_9639_65102"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.1136 23.7223) scale(16.3264 17.5818)"
        >
          <stop stopColor="#C26715" />
          <stop offset="0.508" stopColor="#B85515" />
          <stop offset="1" stopColor="#AD3F16" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_9639_65102"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.4967 25.2576) scale(17.3285 18.1209)"
        >
          <stop stopColor="#C26715" />
          <stop offset="0.508" stopColor="#B85515" />
          <stop offset="1" stopColor="#AD3F16" />
        </radialGradient>
      </defs>
    </svg>
  );
};
