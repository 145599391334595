import { FC } from "react";
// import { Button } from "components";
import classes from "./BenefitCard.module.scss";

interface IBenefitCardProps {
  isEven?: boolean;
  icon: FC;
  imgSrc: string;
  title: string;
  description: string;
  onClick: () => void;
}

export const BenefitCard: FC<IBenefitCardProps> = ({
  isEven,
  icon: Icon,
  imgSrc,
  title,
  description,
  onClick,
}) => {
  return (
    <div className={classes.BenefitCard}>
      <div className={classes.ContentBlockMiniScreen}>
        <div className={classes.BenefitImage}>
          <img src={imgSrc} alt={title} />
          <div className={classes.Brightness} />
        </div>
        <div className={classes.Circle}>
          <Icon />
        </div>
        <h4>{title}</h4>
        <p>{description}</p>
        {/* <Button onClick={onClick}>СТАРТ</Button> */}
      </div>
      <div className={classes.ContentBlockLargeScreen}>
        <div
          className={`${classes.ImageBlock} ${
            isEven && classes.ImageBlockEven
          }`}
        >
          <img src={imgSrc} alt={title} />
        </div>
        <div className={classes.TextBlock}>
          <div className={classes.Circle}>
            <Icon />
          </div>
          <h4>{title}</h4>
          <p>{description}</p>
          {/* <Button onClick={onClick}>СТАРТ</Button> */}
        </div>
      </div>
    </div>
  );
};
