import { FC } from "react";
// import { Button } from "components";
// import {
//   TelegramIcon,
//   FacebookIcon,
//   InstagramIcon,
//   MessengerIcon,
// } from "assets";
// import { COLORS } from "utils";
import HeaderMobileImg from "assets/images/headerMobile.png";
import HeaderDesktopImg from "assets/images/headerDesktop.png";

import classes from "./Header.module.scss";

export const Header: FC = () => {
  return (
    <header className={classes.Header}>
      <div className={classes.HeaderMobile}>
        <div className={classes.HeaderMobileImg}>
          <img src={HeaderMobileImg} alt="header" />
        </div>
        {/* <Button>Старт</Button> */}
      </div>
      <div className={classes.HeaderDesktop}>
        <img src={HeaderDesktopImg} alt="header" className={classes.HeaderDesktopImg} />

        {/* <div className={classes.Navbar}>
          <nav>
            <ul>
              <li>
                <a href="#about">О нас</a>
              </li>
              <li>
                <a href="#services">Плюсы</a>
              </li>
              <li>
                <a href="#news">Достижения</a>
              </li>
              <li>
                <a href="#contacts">Опыт</a>
              </li>
            </ul>
          </nav>
          <Button>Старт</Button>
        </div> */}
        {/* <ul className={classes.SocialLinks}>
          <li>
            <a
              target="blank"
              href="https://t.me/Abdullayev_8586"
              rel="noopener noreferrer"
            >
              <TelegramIcon color={COLORS.BLUE} />
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://t.me/Abdullayev_8586"
              rel="noopener noreferrer"
            >
              <InstagramIcon color={COLORS.BLUE} />
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://t.me/Abdullayev_8586"
              rel="noopener noreferrer"
            >
              <FacebookIcon color={COLORS.BLUE} />
            </a>
          </li>
          <li>
            <a
              target="blank"
              href="https://t.me/Abdullayev_8586"
              rel="noopener noreferrer"
            >
              <MessengerIcon color={COLORS.BLUE} />
            </a>
          </li>
        </ul> */}
      </div>
    </header>
  );
};
