import {
  AboutUs,
  Awards,
  Footer,
  Header,
  OurMission,
  ProjectBenefits,
  ProjectFunctions,
} from "widgets";

export const App = () => {
  return (
    <>
      <Header />
      <AboutUs />
      <OurMission />
      <ProjectFunctions />
      <ProjectBenefits />
      <Awards />
      <Footer />
    </>
  );
};
