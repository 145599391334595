import { FC } from "react";
import classes from "./OurMission.module.scss";
import { SectionTitle, OurMissionCard } from "components";
import { Mission_1_Icon, Mission_2_Icon, Mission_3_Icon } from "assets";

const ourMissionCards = [
  {
    icon: Mission_1_Icon,
    title: "Обеспечить здоровое будущее",
    description:
      "Вдохновляем пользователей заботиться о своем здоровье и физической активности, предоставляя возможность достижения новых вершин в спорте и фитнесе.",
  },
  {
    icon: Mission_2_Icon,
    title: "Эволюция спорт сообщества",
    description:
      "Усиливаем взаимодействие спортсменов и любителей активного образа жизни, содействуя созданию поддерживающего и вдохновляющего спортивного комьюнити.",
  },
  {
    icon: Mission_3_Icon,
    title: "Замотивировать молодежь спортом",
    description:
      "Награждаем наших пользователей за достижения в спорте и фитнесе, вдохновляя на регулярные тренировки и заботу о здоровье через систему ачивок, включая достижение 10 км бега.",
  },
];

export const OurMission: FC = () => {
  return (
    <section className={classes.OurMission}>
      <SectionTitle title="Наша миссия" />
      <div className={classes.CardsContainer}>
        {ourMissionCards.map((card, index) => (
          <OurMissionCard
            key={card.title}
            icon={card.icon}
            title={card.title}
            description={card.description}
          />
        ))}
      </div>
    </section>
  );
};
