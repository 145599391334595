import { FC } from "react";
// import {
//   FacebookIcon,
//   InstagramIcon,
//   MessengerIcon,
//   TelegramIcon,
// } from "assets";
// import { COLORS } from "utils";
import classes from "./Footer.module.scss";

export const Footer: FC = () => {
  const pathToTermsOfUse = "https://docs.gtoapp.ru/terms_of_use";
  const pathToPrivacyPolicy = "https://docs.gtoapp.ru/privacy_policy";
  return (
    <footer className={classes.Footer}>
      <p className={classes.Copyright}>© 2023 ГТО</p>
      <span></span>
      <div className={classes.files}>
        <a
          href={pathToTermsOfUse}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Пользовательское соглашение
        </a>
        <a
          href={pathToPrivacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Политика конфиденциальности
        </a>
      </div>

      {/* <ul className={classes.SocialLinks}>
        <li>
          <a
            target="blank"
            href="https://t.me/Abdullayev_8586"
            rel="noopener noreferrer"
          >
            <TelegramIcon color={COLORS.RED} />
          </a>
        </li>
        <li>
          <a
            target="blank"
            href="https://t.me/Abdullayev_8586"
            rel="noopener noreferrer"
          >
            <InstagramIcon color={COLORS.RED} />
          </a>
        </li>
        <li>
          <a
            target="blank"
            href="https://t.me/Abdullayev_8586"
            rel="noopener noreferrer"
          >
            <FacebookIcon color={COLORS.RED} />
          </a>
        </li>
        <li>
          <a
            target="blank"
            href="https://t.me/Abdullayev_8586"
            rel="noopener noreferrer"
          >
            <MessengerIcon color={COLORS.RED} />
          </a>
        </li>
      </ul> */}
    </footer>
  );
};
