import { FC } from "react";

export const EventOrganizersIcon: FC = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 41.6667V12.5H43.75V41.6667C43.75 42.8125 42.8125 43.75 41.6667 43.75H8.33333C7.1875 43.75 6.25 42.8125 6.25 41.6667Z"
        fill="url(#paint0_linear_9639_65135)"
      />
      <path
        d="M24.6324 19.3781L22.4231 24.3323L17.0293 24.9021C16.6824 24.9385 16.5428 25.3687 16.8012 25.6031L20.8303 29.2344L19.7053 34.5417C19.6335 34.8833 19.9991 35.1489 20.3012 34.9739L25.0001 32.2625L29.6991 34.9729C30.0012 35.1469 30.3668 34.8812 30.2949 34.5406L29.1699 29.2333L33.1991 25.6021C33.4585 25.3687 33.3189 24.9385 32.971 24.901L27.5772 24.3312L25.3678 19.3771C25.2262 19.0594 24.7741 19.0594 24.6324 19.3781Z"
        fill="white"
      />
      <path
        d="M43.75 8.33333V14.5833H6.25V8.33333C6.25 7.1875 7.1875 6.25 8.33333 6.25H41.6667C42.8125 6.25 43.75 7.1875 43.75 8.33333Z"
        fill="url(#paint1_linear_9639_65135)"
      />
      <path
        opacity="0.05"
        d="M36.979 13.0208C35.253 13.0208 33.854 11.6219 33.854 9.89583V6.25H40.104V9.89583C40.104 11.6219 38.705 13.0208 36.979 13.0208Z"
        fill="black"
      />
      <path
        opacity="0.07"
        d="M36.979 12.2396C35.6842 12.2396 34.6353 11.1906 34.6353 9.89583V6.25H39.3228V9.89583C39.3228 11.1906 38.2738 12.2396 36.979 12.2396Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M13.021 13.0208C11.295 13.0208 9.896 11.6219 9.896 9.89583V6.25H16.146V9.89583C16.146 11.6219 14.747 13.0208 13.021 13.0208Z"
        fill="black"
      />
      <path
        opacity="0.07"
        d="M13.021 12.2396C11.7262 12.2396 10.6772 11.1906 10.6772 9.89583V6.25H15.3647V9.89583C15.3647 11.1906 14.3158 12.2396 13.021 12.2396Z"
        fill="black"
      />
      <path
        d="M13.021 11.4583C12.1585 11.4583 11.4585 10.7583 11.4585 9.89579V5.72913C11.4585 4.86663 12.1585 4.16663 13.021 4.16663C13.8835 4.16663 14.5835 4.86663 14.5835 5.72913V9.89579C14.5835 10.7583 13.8835 11.4583 13.021 11.4583Z"
        fill="#3CCBF4"
      />
      <path
        d="M36.979 11.4583C36.1165 11.4583 35.4165 10.7583 35.4165 9.89579V5.72913C35.4165 4.86663 36.1165 4.16663 36.979 4.16663C37.8415 4.16663 38.5415 4.86663 38.5415 5.72913V9.89579C38.5415 10.7583 37.8415 11.4583 36.979 11.4583Z"
        fill="#3CCBF4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9639_65135"
          x1="6.68125"
          y1="14.2313"
          x2="36.7865"
          y2="36.2198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#28AFEA" />
          <stop offset="1" stopColor="#047ED6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9639_65135"
          x1="6.6875"
          y1="6.88333"
          x2="42.9146"
          y2="14.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0077D2" />
          <stop offset="1" stopColor="#0B59A2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
