import { FC } from "react";
import classes from "./AboutUsCard.module.scss";
import { SectionSubTitle } from "components";

interface IAboutUsCardProps {
  icon: FC<any>;
  title: string;
  description: string;
  className?: string;
}

export const AboutUsCard: FC<IAboutUsCardProps> = ({
  icon: Icon,
  title,
  description,
  className,
}) => {
  return (
    <div className={`${classes.AboutUsCard} ${className}`}>
      <div className={classes.MetaData}>
        <Icon className={classes.Icon} />
        <SectionSubTitle title={title} className={classes.Title} />
      </div>
      <div
        className={classes.Text}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </div>
  );
};
