import { FC } from "react";
import { SectionTitle } from "components";
import AwardsPhone1 from "assets/images/awardsPhone1-min.png";
import AwardsPhone2 from "assets/images/awardsPhone2-min.png";
import AwardsPhone3 from "assets/images/awardsPhone3-min.png";
import AwardsBackGr from "assets/images/redlines.png";
import classes from "./Awards.module.scss";

export const Awards: FC = () => {
  return (
    <>
      <section className={classes.Awards}>
        <SectionTitle title="Награды" />
        <div className={classes.Phones}>
          <img
            src={AwardsPhone1}
            alt="Award for silver"
            className={classes.FirstImg}
          />
          <img
            src={AwardsPhone2}
            alt="Award for gold"
            className={classes.SecondImg}
          />
          <img
            src={AwardsPhone3}
            alt="Award for bronze"
            className={classes.ThirdImg}
          />
        </div>
        {/* <AwardsBg className={classes.RedLines} /> */}
        <img src={AwardsBackGr} alt="redlines" className={classes.RedLines} />
      </section>
    </>
  );
};
