import { FC } from "react";
import { AboutUsCard, SectionTitle } from "components";
import GtoGoldenLogo from "assets/images/gtoLogo.png";
import BlackLines from "assets/images/blackLines.png";

import classes from "./AboutUs.module.scss";
import { aboutUsCardsFirstColumn, aboutUsCardsSecondColumn } from "./data";

export const AboutUs: FC = () => {
  return (
    <section className={classes.AboutUs}>
      <SectionTitle title="О нас" />
      <div className={classes.AboutUsContent}>
        <div className={classes.CardsBox}>
          {aboutUsCardsFirstColumn.map((item, index) => (
            <AboutUsCard
              {...item}
              key={item.title}
              className={index % 2 ? classes.Ml : classes.Mr}
            />
          ))}
        </div>
        <div className={classes.GtoLogo}>
          <img src={BlackLines} className={classes.BlackLinesImg} alt="Black Lines" />
          <img src={GtoGoldenLogo} className={classes.LogoImg} alt="GTO Golden Logo" />
        </div>
        <div className={classes.CardsBox}>
          {aboutUsCardsSecondColumn.map((item, index) => (
            <AboutUsCard
              {...item}
              key={item.title}
              className={index % 2 ? classes.Mr : classes.Ml}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
