import { FC } from "react";

export const Benefit_1_Icon: FC = () => {
  return (
    <svg
      width="50"
      height="70"
      viewBox="0 0 50 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 36.7711L49.5 0L26.3047 30.6988H49.5L0.5 70L26.3047 36.7711H0.5Z"
        fill="#A52D2E"
      />
    </svg>
  );
};
