import { FC } from "react";

import classes from "./FunctionTextCard.module.scss";

interface ICardProps {
  icon: FC;
  text: string;
}

export const FunctionTextCard: FC<ICardProps> = ({ icon: Icon, text }) => {
  return (
    <div className={classes.FunctionTextCard}>
      <div className={classes.Icon}>
        <Icon />
      </div>
      <p className={classes.Text}>{text}</p>
    </div>
  );
};
