export const Mission_1_Icon = () => {
  return (
    <svg
      width="42"
      height="30"
      viewBox="0 0 42 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11132_6627)">
        <path
          d="M21.0343 29.4961C19.5568 29.5129 18.0797 29.4355 16.6021 29.4368C15.9164 29.4373 15.243 29.2915 14.5639 29.2105C13.9848 29.1413 13.4308 28.9897 12.8861 28.8087C12.058 28.5335 11.3082 28.109 10.7232 27.4211C10.0985 26.6865 9.7886 25.8235 9.67453 24.8685C9.56179 23.9236 9.62767 22.9867 9.70592 22.0422C9.78462 21.0882 10.0101 20.1722 10.2833 19.272C10.4628 18.6801 10.7064 18.1017 10.9761 17.5378C11.2556 16.9535 11.5624 16.386 11.9174 15.851C12.218 15.3985 12.5408 14.9595 12.9069 14.553C13.1138 14.3236 13.3172 14.0846 13.5351 13.8692C13.9503 13.4587 14.3862 13.0677 14.8601 12.7256C14.9428 12.6658 15.0061 12.6817 15.0653 12.7554C15.5596 13.3709 16.1794 13.8366 16.8324 14.2503C17.3329 14.5671 17.8723 14.8183 18.4347 15.0043C19.8924 15.4853 21.3562 15.603 22.8643 15.2102C23.5142 15.0409 24.1318 14.811 24.7199 14.5096C25.5333 14.0928 26.2743 13.5524 26.8853 12.8527C27.0578 12.6554 27.1577 12.6952 27.331 12.8423C27.8443 13.2786 28.3576 13.714 28.8117 14.2159C29.2759 14.7291 29.707 15.2663 30.0938 15.8474C30.4409 16.3692 30.7406 16.915 31.0218 17.4717C31.4065 18.2334 31.7133 19.038 31.9193 19.8635C32.2867 21.3344 32.5255 22.8338 32.411 24.363C32.3583 25.0672 32.2381 25.7592 31.9936 26.4322C31.6483 27.383 31.032 28.0823 30.1911 28.5665C29.5182 28.9539 28.7697 29.1404 28.0088 29.2834C26.8425 29.502 25.666 29.4812 24.4904 29.4943C23.3382 29.507 22.1861 29.497 21.0339 29.497L21.0343 29.4961Z"
          fill="#B08F5F"
        />
        <path
          d="M14.5768 7.07419C14.4968 3.46402 17.5659 0.452593 21.0658 0.500565C24.6093 0.549443 27.4973 3.5405 27.4672 7.12533C27.4358 10.8274 24.4975 13.7406 20.9177 13.7085C17.4674 13.6777 14.4968 10.6875 14.5768 7.07419Z"
          fill="#B08F5F"
        />
        <path
          d="M30.3564 13.703C30.7543 13.7881 31.0682 13.9877 31.4166 14.0995C32.169 14.3403 32.9313 14.3801 33.7063 14.3475C34.3421 14.3208 34.9482 14.1579 35.5371 13.9239C36.1539 13.6786 36.7118 13.3251 37.2304 12.9088C37.4325 12.7463 37.6164 12.5589 37.7955 12.3698C37.8879 12.272 37.9467 12.2969 38.0391 12.3625C38.6872 12.8255 39.2297 13.3998 39.7333 14.0176C40.0339 14.3864 40.3107 14.7756 40.5397 15.1911C40.8863 15.8197 41.1856 16.4719 41.4191 17.1598C41.6211 17.7545 41.7887 18.3587 41.8705 18.9737C41.9965 19.916 42.0508 20.8641 41.9372 21.8222C41.8638 22.4422 41.687 23.0116 41.3642 23.5198C41.0888 23.9538 40.7086 24.2923 40.2382 24.5345C39.8739 24.7223 39.4963 24.8567 39.1094 24.9468C38.1394 25.1726 37.1486 25.2296 36.1556 25.2314C35.4695 25.2328 34.7837 25.2201 34.0976 25.2265C33.8977 25.2283 33.8279 25.2124 33.8341 24.9549C33.8703 23.4822 33.8946 22.015 33.5524 20.5577C33.3358 19.6349 33.0997 18.7284 32.7646 17.8436C32.4807 17.0946 32.1288 16.3868 31.7336 15.6957C31.337 15.0024 30.863 14.3683 30.3555 13.7035L30.3564 13.703Z"
          fill="#B08F5F"
        />
        <path
          d="M11.6485 13.74C11.2891 14.1944 10.9676 14.6099 10.6825 15.0539C10.3142 15.6273 9.97905 16.217 9.67974 16.8325C9.40341 17.4009 9.16246 17.9852 8.97279 18.5821C8.68762 19.48 8.46435 20.3974 8.3326 21.3392C8.19112 22.3484 8.08943 23.3554 8.14735 24.3746C8.15929 24.5891 8.1814 24.8055 8.22384 25.0155C8.25479 25.1693 8.23445 25.2245 8.06821 25.2245C6.89881 25.2227 5.73294 25.168 4.56574 25.0842C3.69831 25.0222 2.87243 24.8281 2.0775 24.5022C1.69064 24.3434 1.32987 24.1112 1.03454 23.7768C0.805962 23.5179 0.591534 23.2608 0.440329 22.9463C0.209985 22.4661 0.0596646 21.9588 0.0238529 21.4293C-0.0544022 20.2748 0.0618752 19.1315 0.343505 18.0132C0.504437 17.3751 0.724612 16.7551 0.99961 16.1527C1.25869 15.5861 1.54563 15.0389 1.90153 14.5352C2.39538 13.836 2.94803 13.1856 3.62182 12.6557C3.75048 12.5543 3.86941 12.4403 4.00072 12.3407C4.12981 12.2425 4.17182 12.2814 4.2514 12.3692C4.71739 12.882 5.2581 13.2992 5.86292 13.6219C6.53096 13.9781 7.22819 14.2352 7.98996 14.313C8.6863 14.3841 9.37777 14.3836 10.0595 14.2469C10.5021 14.1582 10.9464 14.0378 11.3461 13.8007C11.4239 13.7545 11.5088 13.7505 11.6489 13.7391L11.6485 13.74Z"
          fill="#B08F5F"
        />
        <path
          d="M8.81405 2.88291C11.5698 2.87839 13.7799 5.17972 13.7782 7.95762C13.7764 10.76 11.5822 13.0355 8.81184 13.0527C6.03135 13.0699 3.82164 10.7468 3.81059 7.96757C3.79953 5.2028 6.02384 2.84987 8.81405 2.88291Z"
          fill="#B08F5F"
        />
        <path
          d="M38.1772 7.95798C38.2829 10.7445 35.8849 13.0721 33.2414 13.058C30.4711 13.0435 28.2817 10.7775 28.2561 8.00822C28.2296 5.16741 30.4892 2.89097 33.2295 2.87241C35.8787 2.85476 38.2882 5.2086 38.1768 7.95798H38.1772Z"
          fill="#B08F5F"
        />
      </g>
      <defs>
        <clipPath id="clip0_11132_6627">
          <rect
            width="42"
            height="29"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
